@import url(https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap);
.cursive {
  font-family: sans-serif;
  font-weight: 700;
}

.prose img {
  margin-left: auto;
  margin-left: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

